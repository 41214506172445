<template>
  <div
    v-if="user.id == nft.uowner.id || myprofile.role == 1"
    class="q-mt-lg q-pa-md flex row justify-center"
  >
    <q-form ref="form" class="q-gutter-md nft-form">
      <div class="">
        <input
          type="file"
          ref="nft_file"
          @change="upload_nft"
          class="hidden"
          accept="image/*"
        />

        <q-img
          v-if="nft_src == null"
          class="nft-img"
          :ratio="1"
          src="@/assets/placeholder.jpg"
          @click.stop="myprofile.role == 1 ? nft_file.click() : null"
          :style="upload_file_style"
        >
          <div v-if="myprofile.role == 1" class="overlay">
            <q-icon class="myicon absolute-center" size="xl" name="edit" />
          </div>
        </q-img>
        <q-img
          v-else
          :ratio="1"
          class="nft-img"
          :src="nft_src"
          @click.stop="myprofile.role == 1 ? nft_file.click() : null"
        >
          <div v-if="myprofile.role == 1" class="overlay">
            <q-icon class="myicon absolute-center" size="xl" name="edit" />
          </div>
        </q-img>
        <p class="q-ma-sm text-body">{{ $t("upload_nft") }}</p>
      </div>

      <q-input
        filled
        v-model="nft_name"
        :label="$t('nft_title')"
        :hint="$t('choose_your_nft_name')"
        lazy-rules
        :rules="[(val) => (val && val.length > 0) || 'Please type something']"
      />

      <q-input
        filled
        v-model="nft_author"
        :label="$t('nft_author')"
        :hint="$t('nft_author')"
        lazy-rules
        :rules="[(val) => (val && val.length > 0) || 'Please type something']"
      />

      <q-input
        filled
        type="textarea"
        v-model="nft_desc"
        :label="$t('nft_desc')"
        lazy-rules
        :rules="[(val) => (val && val.length > 0) || 'Please type something']"
      />

      <q-input
        filled
        v-model="nft_link"
        :label="$t('external_link')"
        hint="External Link,url start with https://"
      />

      <q-input
        filled
        v-model="nft_audio"
        :label="$t('audio')"
        hint="Set your audio url,start with https://"
      />

      <q-input
        filled
        v-model="nft_video"
        :label="$t('video')"
        hint="Set your video url, start with https://"
      />

      <q-input
        v-if="myprofile.ulevel >= 2"
        filled
        v-model.number="nft_price"
        type="number"
        :label="$t('nft_price')"
        hint="Set your NFT sell price"
        :rules="[(val) => parseInt(val) >= 0 || 'Issue price can not be negative']"
      />
      <q-input
        v-else
        filled
        v-model.number="nft_price"
        type="number"
        :label="$t('nft_price')"
        hint="Set your NFT sell price"
        :rules="[
          (val) => parseInt(val) >= 3 || 'Issue price be greater than $3',
        ]"
      />

      <!-- <q-input
        filled
        v-model.number="nft_price"
        type="number"
        :label="$t('price')"
        :hint="$t('set_your_nft_sell_price')"
        :rules="[val => ( parseInt(val) == 0 || parseInt(val) >= 3) || 'Issue price must be $0 or greater than $3']"
      /> -->

      <q-input
        filled
        v-model.number="nft_count"
        type="number"
        :label="$t('nft_count')"
        :hint="$t('set_your_nft_token_ammount')"
        :rules="[val => parseInt(val) > 0 || 'Issue quantity must greater than 0']"
      />
      <!-- <q-toggle v-model="accept" label="I accept the license and terms" /> -->

      <q-list>
        <p class="text-body">{{ $t("payment_options") }}</p>
        <q-item class="bg-grey-2">
          <q-item-section avatar>
            <q-checkbox disable v-model="is_credit_card"
              >Credit Card / Google Pay / Apple Pay</q-checkbox
            >
          </q-item-section>
          <q-item-section>
            <!-- <q-input 
             label="Credit Card / Google Pay / Apple Pay"
             v-model="credit_info"></q-input> -->
            <q-item-label caption
              >Bind your Stripe Account, receive payment directly and instantly.
              (Coming soon...)</q-item-label
            >
          </q-item-section>
        </q-item>
        <!-- <q-item class="bg-grey-2">
          <q-item-section avatar>
            <q-checkbox v-model="is_buy_directly"></q-checkbox>
          </q-item-section>
          <q-item-section>
            <q-input v-model="direct_info"></q-input>
            <q-item-label caption>{{$t('buy_direct')}}</q-item-label>
          </q-item-section>
        </q-item> -->
      </q-list>
      <div v-if="myprofile.role == 1">
        <q-input filled v-model.number="nft_flag" type="number" label="Flag" />
      </div>
      <div class="text-body2 myrules">
        {{ $t("content_rules") }}
      </div>
      <div class="text-center q-ma-xl">
        <q-btn
          :loading="minting"
          size="lg"
          class="q-pl-xl q-pr-xl"
          label="SAVE"
          color="primary"
          @click="validate"
        >
          <template v-slot:loading>
            <q-spinner-hourglass class="on-left" />
            Loading
          </template>
        </q-btn>
      </div>
    </q-form>
  </div>
  <div v-else class="q-pa-md flex row justify-center">
    <h5>Access denied!</h5>
  </div>
  <q-dialog v-model="mint_confirm" persistent>
    <q-card>
      <q-card-section class="">
        <div class="text-h6">Save Confirm</div>
      </q-card-section>
      <q-card-section class="text-body"
        >You are saveing current NFT of {{ nft_name }}
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="grey" v-close-popup />
        <q-btn flat label="Save" color="orange" v-close-popup @click="save" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style lang="sass" scoped>
.nft-img
  cursor: pointer
  :hover .overlay
    display: block
    background: rgba(0, 0, 0, .3)
    opacity: 1

.overlay
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  opacity: 0
  background: rgba(0, 0, 0, 0)
  transition: background 0.5s ease
  transition: opacity 0.5s ease

.myrules
  max-width: 960px
</style>

<script>
import { supabase } from "../supabase";
import { ref, onBeforeMount, toRefs, watch,inject } from "vue";
import ax from "axios";
import { w3, APIURL } from "../web3";
import abi from "@/assets/cnft.abi.json";
import bytecode from "@/assets/cnft.bytecode.json";
import { useStore } from "vuex";
import { supabase as s } from "../supabase";
import { useQuasar } from "quasar";
import { useRoute } from "vue-router";

export default {
  name: "CreateMint",
  setup(prop, { emit }) {
    const nft = ref();
    const uploading = ref(false);
    const nft_src = ref(null);
    const nft_file = ref(null);
    const nft_name = ref("");
    const nft_author = ref("");
    const nft_desc = ref("");
    const nft_link = ref("");
    const nft_video = ref("");
    const nft_audio = ref("");
    const nft_price = ref("0");
    const nft_count = ref("1");
    const nft_contract = ref("");
    const is_credit_card = ref(false);
    const is_buy_directly = ref(false);
    const direct_info = ref("");
    const credit_info = ref("");
    const coinbase_info = ref("");
    const nft_flag = ref(0);
    const q = useQuasar();
    const user = ref(s.auth.user());
    const r = useRoute();
    onBeforeMount(async () => {
      let { data: nfts, error } = await s
        .from("nfts")
        .select("*,uowner:uowner(*)")
        .eq("id", r.params.id);
      if (error) console.log(error);
      nft.value = nfts[0];

      nft_src.value = nft.value.data.image;
      nft_name.value = nft.value.data.name;
      nft_author.value = nft.value.data.author;
      nft_desc.value = nft.value.data.description;
      nft_link.value = nft.value.data.link;
      nft_price.value = nft.value.data.price;
      nft_video.value = nft.value.data.video;
      nft_audio.value = nft.value.data.audio;
      nft_count.value = nft.value.data.count;
      nft_contract.value = nft.value.id;
      nft_flag.value = nft.value.flag;
      is_credit_card.value = nft.value.data.is_credit_card;
      is_buy_directly.value = nft.value.data.is_buy_directly;
      direct_info.value = nft.value.data.direct_info;
      credit_info.value = nft.value.data.credit_info;
      console.log("nft object:", nft.value);
    });
    return {
      myprofile: inject("profile"),
      user,
      // nft info
      nft,
      nft_name,
      nft_author,
      nft_desc,
      nft_link,
      nft_video,
      nft_audio,
      nft_price,
      nft_count,
      nft_contract,
      nft_src,
      nft_file,
      nft_flag,
      is_credit_card,
      is_buy_directly,
      direct_info,
      credit_info,
      coinbase_info,

      //control
      uploading,
      minting: ref(false),
      mint_confirm: ref(false),
      upload_file_style: ref(""),
      q,
    };
  },
  methods: {
    validate() {
      if (!this.nft_src) {
        this.upload_file_style = "border:2px solid red";
        return;
      }
      this.$refs.form.validate().then((res) => {
        if (res) {
          this.mint_confirm = true;
        }
      });
    },
    async upload_nft(evt) {
      const files = evt.target.files;
      try {
        if (!files || files.length === 0) {
          throw new Error("You must select an image to upload.");
        }

        const file = files[0];
        const ext = file.name.split(".").pop();
        const fname = `${Math.random()}.${ext}`;
        const fpath = "nft/" + `${fname}`;
        let { error: uploadError } = await s.storage
          .from("cnft")
          .upload(fpath, file);
        if (uploadError) throw uploadError;
        const { publicURL, error } = await s.storage
          .from("cnft")
          .getPublicUrl(fpath);
        if (error) console.log(error);
        this.nft_src = publicURL;
      } catch (error) {
        alert(error.message);
      }
    },

    async _updatedb(contract, count) {
      console.log("Update NFT");
      const record = {
        flag: this.nft_flag,
        data: this._get_nft_json(),
      };
      console.log(record);
      const { data, error } = await supabase
        .from("nfts")
        .update(record)
        .match({ id: this.nft.id });
      if (error) {
        console.log(error);
      } else console.log(data);
    },

    _get_nft_json() {
      console.log("Generate json file");
      const metadata = {
        issuer: "0xffb2ca78e8e72411e82d8684ac752846868d4f86",
        name: this.nft_name,
        description: this.nft_desc,
        image: this.nft_src,
        link: this.nft_link,
        author: this.nft_author,
        price: this.nft_price,
        count: this.nft_count,
        audio: this.nft_audio,
        video: this.nft_video,
        direct_info: this.direct_info,
        credit_info: this.credit_info,
        is_credit_card: this.is_credit_card,
        is_buy_directly: this.is_buy_directly,
      };
      console.log(metadata);
      return metadata;
    },

    async save() {
      this.minting = true;
      this.q.loading.show({
        message: "Saving...",
      });

      await this._updatedb(this.nft_contract, this.nft_count);

      this.q.loading.hide();
      this.q.notify("Update NFT Success!");
      this.minting = false;
      this.$router.push("/shop/" + this.user.id);
    },
  },
};
</script>
